import ImagesDisplay from "../../components/ImagesDisplay";
import classes from "./Home.module.css";
import bulletPoint from "../../assets/utils/BulletStatement";
import Products from "../Products";
import ReadMoreSection from "../../components/ReadMoreSection";
export default function Home() {
  return (
    <div className={classes.homeContainer}>
      <ImagesDisplay imageHeight="300px" imageWidth="1300px"
        imageUrls={[
          "./Website Photos/Spices Sliding Homepage 1.jpg",
          "./Website Photos/Spices Sliding Homepage 2.jpg",
          "./Website Photos/Spices Sliding Homepage 3.jpg",
        ]}
      ></ImagesDisplay>
      <div className={classes.textContent}>
        <p style={{ paddingTop: "20px" }}>
          <b style={{ color: "green", fontSize: "25px" }}>
            Ghewarchand Keshrichand Agro Industries
          </b>
          is a reputed organization producing a variety of products like Cumin
          Seed (Jeera), Fenugreek (Methi), Fennel Seed (Saunf), Psyllium Husk,
          Psyllium Husk Powder, Psyllium Seed, Psyllium Kha-Kha Powder and
          Cattle Feed.
        </p>
        <br />
        <p>
          We at GK Agro have knowledge and dedication for quality in trade of
          spices and seeds. From processing to storage, quality checks,
          transportation and distribution makes us a reliable partner all over
          the world.
        </p>
        <br />
        <p>
          Our manufacturing plant is spread over an area of more tha 60,000
          square feet and has best equipments and machinery equipped with latest
          technology, enough warehouses for storage facility, laboratory for
          product testing and an administrative building.
        </p>
        <br />
        <div className={classes.whyUS}>
          <h3 style={{ textDecoration: "underline" }}>Why choose GK AGRO ?</h3>
          <div>
            {bulletPoint(
              "Our strategic location – Nokha, Rajasthan is a hub for agriculture in spices, seeds, cereals and pulses which gives a special benefit to our local and overseas buyers"
            )}
            {bulletPoint(
              "We are associated with the farmers directly to procure the raw material like psyllium seeds, cumin seeds, etc according to the quality required and at the optimum price"
            )}
            {bulletPoint("Quality")}
            {bulletPoint("Intergrity")}
            {bulletPoint("Competetive Pricing")}
            {bulletPoint("Reliability")}
            {bulletPoint("Relationship Building")}
            {bulletPoint("Excellence")}
            {bulletPoint("Transparency")}
            {bulletPoint("Innovation")}
          </div>
        </div>
      </div>
      <Products></Products>
      <ReadMoreSection
        heading="Infrastructure"
        image1Name="infra1.jpg"
        image2Name="infra2.jpg"
        image3Name="infra3.jpg"
        link="/Infrastructure"
      >
        Our manufacturing plant is strategically located in Rajasthan, which is
        one of the major ISABGOL (PSYLLIUM HUSK) crop producing states in India.
        Spread across an expansive area of 60,000 square feet, our manufacturing
        facility is equipped with the most innovative technology and adheres to
        international ..................
      </ReadMoreSection>
      <img
        className={classes.map}
        src="/Website Photos/SpicesMap2.jpg"
        alt=""
      />
    </div>
  );
}
