import classes from "./Products.module.css";
import ReadMoreSection from "../../components/ReadMoreSection";
export default function Products() {
  return (
    <div className={classes.productsWrapper}>
      <ReadMoreSection
        heading="Products"
        image1Name="Psyllium1.jpg"
        image2Name="Fennel1.jpg"
        image3Name="Fenugreek3.jpg"
        link="/products/Fenugreek"
      >
        Ghewarchand Keshrichand Agro Industriesis a reputed organization
        producing a variety of products like Cumin Seed (Jeera), Fenugreek
        (Methi), Fennel Seed (Saunf), Psyllium Husk, Psyllium Husk Powder,
        Psyllium Seed, Psyllium Kha-Kha Powder and Cattle Feed..................
      </ReadMoreSection>

      <ReadMoreSection
        heading="Cumin"
        image1Name="Cumin1.jpg"
        image2Name="Cumin2.jpg"
        image3Name="Cumin3.jpg"
        link="/products/Cumin"
      >
        Cumin is a spice made from the dried seed of a plant from the parsley
        family known as Cuminum cyminum; the fruit of the plant is called the
        cumin seed. It is native to Asia, Africa, and Europe. However, people
        all around the world use it to flavor meals. People usually buy cumin in
        the form of whole dried seeds or as ground powder. It is a typical
        ingredient in many spice blends, such as curry powder. One of the most
        popular and essential spices of ..................
      </ReadMoreSection>
      <ReadMoreSection
        heading="Psyllium"
        image1Name="Psyllium4.jpg"
        image2Name="Psyllium2.jpg"
        image3Name="Psyllium3.jpg"
        link="/products/Psyllium"
      >
        Increasing number of health-conscious individuals are consuming food
        that contains additional minerals and fibers. The food offered by
        different manufacturers today goes through a lot of processing, which
        takes away the necessary minerals and fibers from it. Therefore the
        increase is demand for natural remedies in OTC pharmaceuticals segment
        has grown and Isabgol is considered to be more beneficial and has less
        side effects compared ..................
      </ReadMoreSection>
      <ReadMoreSection
        heading="Fennel"
        image1Name="Fennel1.jpg"
        image2Name="Fennel3.jpg"
        image3Name="Fennel2.jpg"
        link="/products/Fennel"
      >
        Fennel seeds have a recognizable long, thing shape and are pale green or
        brown in color. They give a sweet licorice-like taste and scent to
        curries, stews, bread, desserts, and beverages. In some parts of the
        world, people chew plain or sugar-coated fennel seeds after
        ..................
      </ReadMoreSection>
      <ReadMoreSection
        heading="Fenugreek"
        image1Name="Fenugreek1.jpg"
        image2Name="Fenugreek2.jpg"
        image3Name="Fenugreek4.jpg"
        link="/products/Fenugreek"
      >
        Fenugreek is a herb used in cooking, medicine, and to hide the taste of
        other medicines. Fenugreek seeds have a smell and taste similar to maple
        syrup. Fenugreek leaves are also eaten as a vegetable in India
        ..................
      </ReadMoreSection>
    </div>
  );
}
