import classes from "./PackageSection.module.css";

export interface PackageSectionProps {
  productPackageURL: string;
}
export default function PackageSection({
  productPackageURL,
}: PackageSectionProps) {
  return (
    <div className={classes.packageWrapper}>
      <img className={classes.firstImage} src={productPackageURL} alt="" />
      <img className={classes.secondImage}  src={productPackageURL} alt="" />
    </div>
  );
}
