import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSeedling } from "@fortawesome/free-solid-svg-icons";
import classes from "./BulletStatement.module.css";
const bulletPoint = (statement: string) => {
  return (

      <div className={classes.bulletPoints}>
        <div className={classes.bulletIcon}>
          <FontAwesomeIcon icon={faSeedling} />
        </div>
        <div className={classes.statement}>{statement}</div>
    </div>
  );
};
export default bulletPoint;
