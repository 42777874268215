import { useState, useEffect } from "react";
export default function useScrollorHeight() {
    const [scrollHeight, setScrollHeight] = useState( document.body.scrollTop)
    useEffect(() => {
        const updatePosition = () => {
            setScrollHeight(window.pageYOffset);
        };
    
        window.addEventListener("scroll", updatePosition);
        updatePosition();
        return () => window.removeEventListener("scroll", updatePosition);
      }, []);
    return scrollHeight
}