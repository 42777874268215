import Button from "../Button";
import MenuList from "../MenuList";
import classes from "./NavBar.module.css";
import Logo from "../Logo";
import { useState, useEffect, useRef } from "react";
import useScreenResize from "../../hooks/useScreenResize";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faXmark } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, NavLink } from "react-router-dom";
import useScrollorHeight from "../../hooks/useScrollorHeight";

export default function NavBar() {
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const [isSticky, setSticky] = useState(false);
  const deviceType = useScreenResize(960);
  const mobNavRef = useRef<HTMLDivElement>(null);
  const handleNavigationState = () => {
    setMobileNavOpen(false);
  };
  const scrollHeight = useScrollorHeight();
  useEffect(() => {
    if (deviceType === "Desktop" && scrollHeight >= 50) {
      setSticky(true);
    } else {
      setSticky(false);
    }
  }, [scrollHeight, deviceType]);

  const navigationOptions = (
    <div className={classes.navigationOptions}>
      {isMobileNavOpen && deviceType === "Mobile" && (
        <div
          className={classes.crossIcon}
          onClick={() => setMobileNavOpen(false)}
        >
          <FontAwesomeIcon icon={faXmark}></FontAwesomeIcon>
        </div>
      )}
      <NavLink to="/">
        {({ isActive }) => (
          <Button
            label="Home"
            width={deviceType === "Desktop" ? "150px" : "100%"}
            type="navButton"
            isActivated={isActive}
            onClick={handleNavigationState}
          ></Button>
        )}
      </NavLink>
      <NavLink to="/Aboutus">
        {({ isActive }) => (
          <Button
            label="About Us"
            width={deviceType === "Desktop" ? "150px" : "100%"}
            type="navButton"
            isActivated={isActive}
            onClick={handleNavigationState}
          ></Button>
        )}
      </NavLink>

      <NavLink to="/Infrastructure">
        {({ isActive }) => (
          <Button
            label="Infrastructure"
            width={deviceType === "Desktop" ? "150px" : "100%"}
            type="navButton"
            isActivated={isActive}
            onClick={handleNavigationState}
          ></Button>
        )}
      </NavLink>
      <NavLink to="/products" style={{width:`${deviceType === "Desktop" ? "150px" : "100%"}`}}>
        {({ isActive }) => (
          <MenuList
            label="Products"
            width="100%"
            isActivated={isActive}
            optionsList={[
              { label: "Psyllium", link: "products/Psyllium" },
              { label: "Cumin", link: "products/Cumin" },
              { label: "Fennel", link: "products/Fennel" },
              { label: "Fenugreek", link: "products/Fenugreek" },
            ]}
            flowDirection={deviceType === "Mobile" ? "left" : "down"}
            onClick={handleNavigationState}
          ></MenuList>
        )}
      </NavLink>



      <NavLink to="/Certifications">
        {({ isActive }) => (
          <Button
            label="Certifications"
            width={deviceType === "Desktop" ? "150px" : "100%"}
            type="navButton"
            isActivated={isActive}
            onClick={handleNavigationState}
          ></Button>
        )}
      </NavLink>
    </div>
  );

  useEffect(() => {
    function outsideClick(event: any) {
      if (mobNavRef.current)
        if (!mobNavRef.current.contains(event.target)) {
          setMobileNavOpen(false);
        }
    }
    document.addEventListener("mousedown", outsideClick);
    return () => document.removeEventListener("mousedown", outsideClick);
  });
  return (
    <>
      {" "}
      <div
        className={classes.navBarContainer}
        style={{
          position: `${
            isSticky && deviceType === "Desktop" ? "fixed" : "relative"
          }`,
          backgroundColor: `${isSticky ? "#2aff00" : "transparent"}`,
          boxShadow: `${isSticky ? "var(--box-shadow)" : "unset"}`,
        }}
      >
        {!isSticky && <Logo></Logo>}
        {deviceType === "Mobile" && (
          <div
            className={classes.navIcons}
            onClick={() => setMobileNavOpen(true)}
          >
            {!isMobileNavOpen && (
              <FontAwesomeIcon icon={faBars}></FontAwesomeIcon>
            )}
          </div>
        )}
        {deviceType === "Desktop" && navigationOptions}
        {deviceType === "Mobile" && (
          <>
            <div className={classes.mobileNav} ref={mobNavRef}>
              {
                <div
                  className={`${classes.mobileNavlist} ${
                    isMobileNavOpen ? classes.openNavBar : ""
                  }`}
                >
                  {navigationOptions}
                </div>
              }
            </div>
          </>
        )}
      </div>
      {deviceType === "Desktop" && <hr className={classes.navSeprator} />}
    </>
  );
}
