import { Navigate, useParams } from "react-router-dom";
import useImageLoader from "../../hooks/useImageLoader";
import classes from "./Product.module.css";
import seedData from "../../assets/data.json";
import ImagesDisplay from "../../components/ImagesDisplay";
import useScreenResize from "../../hooks/useScreenResize";
import PackageSection from "../../components/PackageSection";
import bulletPoint from "../../assets/utils/BulletStatement";

export default function Product() {
  const { productName } = useParams<any>();
  // const image = useImageLoader(`${productName as string}.png`);
  let productData = seedData[productName as keyof typeof seedData];
  const deviceType = useScreenResize(960);
  if (productData === undefined) {
    return Navigate({ to: "/notfound" });
  }

  return (
    <div className={classes.productWrapper}>
      <h1>{productName}</h1>
      <ImagesDisplay
        carouselHeight={`${deviceType === "Mobile" ? "250px" : "300px"}`}
        imageHeight={`${deviceType === "Mobile" ? "250px" : "300px"}`}
        imageWidth={`${deviceType === "Mobile" ? "500px" : "600px"}`}
        imageUrls={new Array(productData.noOfPics)
          .fill(null)
          .map((_, index: number) => {
            return `/Website Photos/${productName}${index + 1}.jpg`;
          })}
      ></ImagesDisplay>
      <div className={classes.productContent}>
        <div className={classes.introduction}>
          {productData.introduction.map((intro, index) => {
            return (
              <p
                className={`${index === 0 ? classes["productTitlePara"] : ""}`}
              >
                {intro}
              </p>
            );
          })}
        </div>
        <div className={classes.productDescriptionSection}>
          <div className={classes.packageImage}>
            <PackageSection
              productPackageURL={`/Website Photos/${productName}Pack.png`}
            ></PackageSection>
          </div>
          <div className={classes.productDescription}>
            {!productData.additionalData && productData.specification && (
              <table>
                <tr>
                  <th>Botanical Name: </th>
                  <td>{productData.specification.botanicalName}</td>
                </tr>
                <tr>
                  <th>Common Names: </th>
                  <td>{productData.specification.commonName.join(", ")}</td>
                </tr>
                <tr>
                  <th>Purity: </th>
                  <td>{productData.specification.purity.join(", ")}</td>
                </tr>
                <tr>
                  <th>Moisture: </th>
                  <td>{productData.specification.moisture.join(", ")}</td>
                </tr>
                <tr>
                  <th>Types: </th>
                  <td>{productData.specification.types.join(", ")}</td>
                </tr>
                <tr>
                  <th>GMO: </th>
                  <td>{productData.specification.GMO}</td>
                </tr>
                <tr>
                  <th>Origin: </th>
                  <td>{productData.specification.origin.join(", ")}</td>
                </tr>
                <tr>
                  <th>Harvest Period: </th>
                  <td>{productData.specification.harvestPeriod.join(", ")}</td>
                </tr>
                <tr>
                  <th>Colour: </th>
                  <td>{productData.specification.color.join(", ")}</td>
                </tr>
                <tr>
                  <th>Quality: </th>
                  <td>{productData.specification.quality.join(", ")}</td>
                </tr>
              </table>
            )}
            {productData.additionalData && (
              <img src={`/Website Photos/${productName}Data.png`}></img>
            )}
          </div>
        </div>
        {productData.types.length > 0 && <div className={classes.textSection}>
          <h2 className={classes.heading}>Types of {productName}</h2>
          {productData.types.map((type)=>{
            return <><h4 className={classes.subHeading}>{type.label}</h4>
            {type.description.map((desc)=>{
              return bulletPoint(desc)
            })}</>
          })}
        </div>}
        <div className={classes.textSection}>
          <h2 className={classes.heading}>Uses</h2>
          {productData.uses.map((statement: string, index: number) => {
            return bulletPoint(statement);
          })}
        </div>
        <div className={classes.textSection}>
          <h2 className={classes.heading}>
            Health Benefits
          </h2>
          {productData.healthBenefits.map(
            (statement: string, index: number) => {
              return bulletPoint(statement);
            }
          )}
        </div>
      </div>
    </div>
  );
}
