import classes from "./FixedImage.module.css";
import { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import useImageLoader from "../../hooks/useImageLoader";
export interface FixedImageProps {
  imageName: string;
  top?: string;
  bottom?: string;
  left?: string;
  right?: string;
}
export default function FixedImage({
  imageName,
  top = "unset",
  bottom = "unset",
  left = "unset",
  right = "unset",
}: FixedImageProps) {
  const image = useImageLoader(imageName)
  return (
    <>
      {createPortal(
        <div
          className={classes.fixedLogoWrapper}
          style={{ top, bottom, left, right }}
        >
          <img className={classes.fixedImage} src={image} />
        </div>,
        document.getElementById("modalRoot") as HTMLElement
      )}
    </>
  );
}
