import NavBar from "../NavBar"
import classes from "./Header.module.css"
import useScreenResize from "../../hooks/useScreenResize";
import useScrollorHeight from "../../hooks/useScrollorHeight"
export  default function Header(){
    const deviceType = useScreenResize(960);
    const scrollHeight =  useScrollorHeight()
    return <header style={{padding:`${scrollHeight>165 && deviceType === "Desktop"?"0px":"30px 30px 10px 30px"}`}} className={classes.headerContainer}>
        <NavBar></NavBar>
    </header>
}
