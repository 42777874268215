import classes from "./AboutUs.module.css";
export default function AboutUs() {
  return (
    <div className={classes.aboutUsWrapper}>
        <h1>About Us</h1>
      <div className={classes.aboutContent}>
        <p id="intro">
          GK Agro based out of Nokha, Rajasthan is engaged in international
          trade and manufacturing of Agricultural commodities like spices,
          Psyllium products, Oil seeds, Animal feed, Grains, pulses, etc
        </p>
        <h3 className={classes.headings}>MISSION</h3>
        <p>
          At GK Agro we aim to provide best quality Psyllium products, seeds,
          spices and pulses to our customers as per their needs. We aim to build
          long term relationship with our customers by not compromising with the
          quality and fulfilling their expectations from us
        </p>
        <h3 className={classes.headings}>VISION</h3>
        <p>
          Our vision is to become the leading manufacturer and supplier of
          Psyllium Husk (Isabgol) along with other spices and seeds, while
          contributing to the overall well being of our customers.
        </p>
        <p>
          We strive to promote the use of natural remedies and enhance the
          quality of life through our products.
        </p>
        <p>
          With a commitment to promoting natural health and wellness we want to
          provide our customers with premium grade ISABGOL meeting the industry
          standards
        </p>
        <h3 className={classes.headings}> OUR VALUES</h3>
        <p>
          We never tend to compromise the quality of products for the sake of
          increasing profit margins. We always believe in providing the best
          quality and genuine products to our consumers hoping that our
          consumers also believe in us for getting a healthier and good life for
          themselves.
        </p>
        <p>
          No company can be run by a single individual. We believe in teamwork
          and trust in our employees, staff and workers in the role designated
          to them, maintain a healthy working environment for them and take all
          necessary measures to keep them and their families safe and support
          them in the times required.
        </p>
      </div>
    </div>
  );
}
