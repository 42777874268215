import React, {useState, useRef, useEffect, ReactNode, WheelEvent} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft, faChevronRight, faChevronUp, faChevronDown} from "@fortawesome/free-solid-svg-icons";
import classes from "./CarouselSlider.module.css"

export type CarouselSliderProps = {
	children: ReactNode;
	scrollDistance?: number;
	hasScrollBar?: boolean;
	orientation?: "vertical" | "horizontal";
	gap?: string;
	height?: string;
}
const CarouselSlider: React.FC<CarouselSliderProps> = (
	{
		children, scrollDistance = 200, hasScrollBar = true, orientation = "horizontal", gap = "16px", height = "500px"
 	}: CarouselSliderProps) => {

	const carouselItemsDiv = useRef<HTMLDivElement>(null);
	const scrollRef = useRef<HTMLDivElement>(null);
	const carouselWrapperDiv = useRef<HTMLDivElement>(null);
	const [buttonsVisible, setButtonsVisible] = useState({left: false,right: false,up: false, down: false})
	const [carouselTrans, setcarouselTrans] = useState({x:0,y:0});
	const [scrollableArea, setScrollableArea] = useState({x:0,y:0});
	const [scrollBarVisible, setScrollBarVisible] = useState<{x: boolean,y: boolean}>({x:hasScrollBar,y:hasScrollBar});
	const [canScroll, setCanScroll] = useState<boolean>(true);
	const [timerID, setTimerID] = useState<NodeJS.Timeout | null>(null);

	useEffect(() => {
		if (carouselItemsDiv.current && carouselWrapperDiv.current) {
			if (carouselItemsDiv.current.clientWidth > carouselWrapperDiv.current.clientWidth && orientation === "horizontal") {
				carouselWrapperDiv.current.style.minHeight = carouselItemsDiv.current.clientHeight + "px"
				setButtonsVisible((prev)=>({...prev,right:true}))
			}
			if (carouselItemsDiv.current.clientHeight > carouselWrapperDiv.current.clientHeight && orientation === "vertical") {
				carouselWrapperDiv.current.style.minWidth = carouselItemsDiv.current.clientWidth + "px"
				setButtonsVisible((prev)=>({...prev,down:true}))
			}
		}
	}, [carouselItemsDiv.current?.clientHeight,carouselItemsDiv.current?.clientWidth])


	useEffect(()=>{
		if(scrollRef.current){
			(scrollRef.current as HTMLDivElement).scrollLeft = -carouselTrans['x'];
		}
	},[hasScrollBar])


	useEffect(() => {
		if (carouselItemsDiv.current && carouselItemsDiv.current?.clientWidth && carouselWrapperDiv.current?.clientWidth) {
			const resizeObserver = new ResizeObserver(() => {
				if(carouselWrapperDiv.current!.clientWidth >= carouselItemsDiv.current!.clientWidth && orientation === "horizontal"){
					setScrollBarVisible((prev)=>({...prev,x:false}))
				}
				if(carouselWrapperDiv.current!.clientHeight >= carouselItemsDiv.current!.clientHeight && orientation === "vertical"){
					setScrollBarVisible((prev)=>({...prev,y:false}))
				}
				if (carouselItemsDiv.current!.clientWidth > carouselWrapperDiv.current!.clientWidth) {
					setScrollableArea((prev)=>({...prev,x:carouselItemsDiv.current!.clientWidth - carouselWrapperDiv.current!.clientWidth}))
					setButtonsVisible((prev)=>({...prev,right:true}))

				} else {
					setScrollableArea((prev)=>({...prev,x:0}))
					setButtonsVisible((prev)=>({...prev,right:false}))
				}
				if (carouselItemsDiv.current!.clientHeight > carouselWrapperDiv.current!.clientHeight) {
					setScrollableArea((prev)=>({...prev,y:carouselItemsDiv.current!.clientHeight - carouselWrapperDiv.current!.clientHeight}))
					setButtonsVisible((prev)=>({...prev,down:true}))

				} else {
					setScrollableArea((prev)=>({...prev,y:0}))
					setButtonsVisible((prev)=>({...prev,down:false}))
				}
			});
			resizeObserver.observe(carouselWrapperDiv.current);
			return () => resizeObserver.disconnect();

		}
	}, [])

	useEffect(() => {
		if (carouselTrans['x'] < 0) {
			setButtonsVisible((prev)=>({...prev,left:true}))
		} else {
			setButtonsVisible((prev)=>({...prev,left:false}))
		}
		if (Math.abs(carouselTrans['x']) < scrollableArea['x']) {
			setButtonsVisible((prev)=>({...prev,right:true}))
		} else {
			setButtonsVisible((prev)=>({...prev,right:false}))
		}

		if (carouselTrans['y'] < 0) {
			setButtonsVisible((prev)=>({...prev,up:true}))
		} else {
			setButtonsVisible((prev)=>({...prev,up:false}))
		}
		if (Math.abs(carouselTrans['y']) < scrollableArea['y']) {
			setButtonsVisible((prev)=>({...prev,down:true}))
		} else {
			setButtonsVisible((prev)=>({...prev,down:false}))
		}

	}, [carouselTrans])

	const handleVariationScroll = (direction: string) => {
		setCanScroll(false)
		setTimerID((prev)=>{
			if(prev){
				clearTimeout(prev)
			}
			return setTimeout(()=>{
				setCanScroll(true)
			},500)
		})
		if (direction == "right") {
			setcarouselTrans((prevState) => {
				if ((scrollableArea['x'] - Math.abs(carouselTrans['x'])) > scrollDistance) {
					return {...prevState,x:(prevState['x'] - scrollDistance)}
				} else {
					setButtonsVisible((prev)=>({...prev,right:false}))
					return  {...prevState,x:-(scrollableArea['x']  + 10)}
				}
			});
			if(scrollRef.current){
				(scrollRef.current as HTMLDivElement).scrollLeft += scrollDistance;
			}

		} else if (direction == "left") {
			setcarouselTrans((prevState) => {
				if (Math.abs(carouselTrans['x']) >= scrollDistance) {
					return {...prevState,x:(prevState['x'] + scrollDistance)}
				} else {
					return {...prevState,x:0}
				}
			});
			if(scrollRef.current){
				(scrollRef.current as HTMLDivElement).scrollLeft -= scrollDistance;
			}

		} else if (direction == "up") {
			setcarouselTrans((prevState) => {
				if (Math.abs(carouselTrans['y']) >= scrollDistance) {
					return {...prevState,y:(prevState['y'] + scrollDistance)}
				} else {
					return {...prevState,y:0}
				}
			});
			if(scrollRef.current){
				(scrollRef.current as HTMLDivElement).scrollTop -= scrollDistance;
			}

		} else if (direction == "down") {
			setcarouselTrans((prevState) => {
				if ((scrollableArea['y'] - Math.abs(carouselTrans['y'])) > scrollDistance) {
					return {...prevState,y:(prevState['y'] - scrollDistance)}
				} else {
					setButtonsVisible((prev)=>({...prev,right:false}))
					return  {...prevState,y:-(scrollableArea['y'] )}
				}
			});
			if(scrollRef.current){
				(scrollRef.current as HTMLDivElement).scrollTop += scrollDistance;
			}

		}

	};


	const handleMouseDown = (event:any) =>{
		setCanScroll(false)
		if(carouselWrapperDiv.current  && carouselItemsDiv.current && scrollRef.current){
			if(carouselWrapperDiv.current.clientWidth >= carouselItemsDiv.current.clientWidth && orientation === "horizontal"){
				return
			}
			if(carouselWrapperDiv.current.clientHeight >= carouselItemsDiv.current.clientHeight && orientation === "vertical"){
				return
			}
			let startX = event.clientX;
			let startY = event.clientY;
			let handleMouseMove = (e: MouseEvent)=>{
				let clientX = e.clientX;
				let clientY = e.clientY;
				let dx = carouselTrans['x'] +  clientX - startX;
				let dy = carouselTrans['y'] +  clientY - startY;

				if(dx > 0){
					dx = 0
				}else if (carouselItemsDiv.current &&  carouselWrapperDiv.current && dx < (-(carouselItemsDiv.current.clientWidth-carouselWrapperDiv.current.clientWidth))){
					dx = -(carouselItemsDiv.current.clientWidth-carouselWrapperDiv.current.clientWidth)
				}
				if(dy > 0){
					dy = 0
				}else if (carouselItemsDiv.current &&  carouselWrapperDiv.current && dx < (-(carouselItemsDiv.current.clientHeight-carouselWrapperDiv.current.clientHeight))){
					dy = -(carouselItemsDiv.current.clientHeight-carouselWrapperDiv.current.clientHeight)
				}
				if(orientation === "horizontal") {
					setcarouselTrans((prev)=>({...prev,x:dx}));
					(scrollRef.current as HTMLDivElement).scrollLeft = -dx;
				}
				if(orientation === "vertical") {
					setcarouselTrans((prev)=>({...prev,y:dy}));
					(scrollRef.current as HTMLDivElement).scrollTop = -dy;
				}
				
			}

			let handleMouseUp = (e: MouseEvent)=>{
				document.removeEventListener('mousemove',handleMouseMove)
				document.removeEventListener('mouseup', handleMouseUp)
				setCanScroll(true)
			}

			document.addEventListener('mousemove',handleMouseMove)
			document.addEventListener('mouseup', handleMouseUp)
		}
        
    }

	const handleTouchStart = (event:any) =>{
		if(carouselWrapperDiv.current  && carouselItemsDiv.current){
			if(carouselWrapperDiv.current.clientWidth >= carouselItemsDiv.current.clientWidth){
				return
			}
			if(carouselWrapperDiv.current.clientHeight >= carouselItemsDiv.current.clientHeight && orientation === "vertical"){
				return
			}
			let startX = event.touches[0].clientX;
			let startY = event.touches[0].clientY;

			let handleTouchMove = (e: TouchEvent)=>{
				let clientX = e.touches[0].clientX;
				let clientY = e.touches[0].clientY;

				let dx = carouselTrans['x'] +  clientX - startX;
				let dy = carouselTrans['y'] +  clientY - startY;

				if(dx > 0){
					dx = 0
				}else if (carouselItemsDiv.current &&  carouselWrapperDiv.current && dx < (-(carouselItemsDiv.current.clientWidth-carouselWrapperDiv.current.clientWidth))){
					dx = -(carouselItemsDiv.current.clientWidth-carouselWrapperDiv.current.clientWidth)
				}
				if(dy > 0){
					dy = 0
				}else if (carouselItemsDiv.current &&  carouselWrapperDiv.current && dx < (-(carouselItemsDiv.current.clientHeight-carouselWrapperDiv.current.clientHeight))){
					dy = -(carouselItemsDiv.current.clientHeight-carouselWrapperDiv.current.clientHeight)
				}
				if(orientation === "horizontal") {
					setcarouselTrans((prev)=>({...prev,x:dx}));
					(scrollRef.current as HTMLDivElement).scrollLeft = -dx;
				}
				if(orientation === "vertical") {
					setcarouselTrans((prev)=>({...prev,y:dy}));
					(scrollRef.current as HTMLDivElement).scrollTop = -dy;
				}
			}

			let handleTouchEnd = (e: TouchEvent)=>{
				document.removeEventListener('touchmove',handleTouchMove)
				document.removeEventListener('touchend', handleTouchEnd)
			}

			document.addEventListener('touchmove',handleTouchMove)
			document.addEventListener('touchend', handleTouchEnd)
		}

    }
	const handleScroll = (event: any) => {
		if(canScroll){
			if(orientation === "horizontal"){
				setcarouselTrans((prev)=>({...prev,x:-(event.target as HTMLDivElement).scrollLeft}))
			}
			if(orientation === "vertical"){
				setcarouselTrans((prev)=>({...prev,y:-(event.target as HTMLDivElement).scrollTop}))
			}
		}
	}
	const handleWheel =(event : WheelEvent) => {
		if(orientation === "horizontal"){
			if(event.deltaY<0){
				(scrollRef.current as HTMLDivElement).scrollLeft -= scrollDistance;
			}else {
				(scrollRef.current as HTMLDivElement).scrollLeft += scrollDistance;
			}
		}else {
			if(event.deltaY<0){
				(scrollRef.current as HTMLDivElement).scrollTop -= scrollDistance;
			}else {
				(scrollRef.current as HTMLDivElement).scrollTop += scrollDistance;
			}
		}


	}

	return (
		<div className={`${classes.wrapper} ${classes[orientation]}`} style={{height:`${orientation === "vertical"?height:"unset"}`}}>
			<div ref={carouselWrapperDiv} 
			onMouseDown={handleMouseDown} 
			onTouchStart={handleTouchStart}
			// onWheel={handleWheel}
			className={`${classes.carouselWrapper} ${classes[orientation]}`}>
			{buttonsVisible["left"] && orientation === "horizontal" && (
				<div
					className={classes.leftButton}
					onClick={() => handleVariationScroll("left")}
				>
					<FontAwesomeIcon className={classes.arrowIconsLeft} icon={faChevronLeft}></FontAwesomeIcon>
				</div>
			)}
			{buttonsVisible["up"] && (
				<div
					className={classes.upButton}
					onClick={() => handleVariationScroll("up")}
				>
					<FontAwesomeIcon className={classes.arrowIconsUp} icon={faChevronUp}></FontAwesomeIcon>
				</div>
			)}
			{buttonsVisible["down"] && (
				<div
					className={classes.downButton}
					onClick={() => handleVariationScroll("down")}
				>
					<FontAwesomeIcon className={classes.arrowIconsDown} icon={faChevronDown}></FontAwesomeIcon>
				</div>
			)}
			<div 
				 ref={carouselItemsDiv}  data-testid="carousel" style={{transform: (orientation === "horizontal"?`translateX(${carouselTrans['x']}px)`:`translateY(${carouselTrans['y']}px)`),gap}}
			     className={`${classes.carouselItems} ${classes[orientation]}`}>
				{children}
			</div>
			{buttonsVisible["right"] && orientation === "horizontal" && (
				<div
					className={classes.rightButton}
					onClick={() => handleVariationScroll("right")}
				>
					<FontAwesomeIcon className={classes.arrowIconsRight} icon={faChevronRight}></FontAwesomeIcon>
				</div>
			)}
			</div>
			{hasScrollBar  && (orientation === "horizontal"?scrollBarVisible['x']:scrollBarVisible['y']) &&
			<div ref={scrollRef} onScroll={handleScroll}  className={`${classes.scrollWrapper} ${classes[orientation]}`} >
				<div 
					style={{width:(orientation === "horizontal"?carouselItemsDiv.current?.clientWidth:"0.1px"),
							height:(orientation === "vertical"? carouselItemsDiv.current?.clientHeight:"0.1px")}}
					className={`${classes.scrollBarDiv} ${classes[orientation]}`}>
				</div>
			</div>}
		</div>
		)
}

export default CarouselSlider;