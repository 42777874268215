import ImageTile from "../ImageTile"
import classes from "./ImagesDisplay.module.css"
import CarouselSlider from "../CarouselSlider"
export interface ImagesDisplayProps {
    imageUrls: string[],
    imageHeight?: string,
    carouselHeight?: string,
    imageWidth?: string
}
export default function ImagesDisplay({imageUrls,carouselHeight="500px",imageHeight="200px",imageWidth="1000px"}:ImagesDisplayProps){
    return <div className={classes.imagesDisplayWrapper}>
        <CarouselSlider
  gap="16px"
  hasScrollBar
  height={carouselHeight}
  orientation="horizontal"
  scrollDistance={200}>
        {imageUrls.map((imageURL,index)=>{
            return <ImageTile key={index} imageScr={imageURL} width={imageWidth} height={imageHeight}></ImageTile>
        })}
        </CarouselSlider>

    </div>
} 