import ImagesDisplay from "../../components/ImagesDisplay";
import useScreenResize from "../../hooks/useScreenResize";
import classes from "./infrastructure.module.css";
export default function Infrastructure() {
  const deviceType = useScreenResize(960);
  return (
    <div className={classes.infraWrapper}>
      <h1>Infrastructure</h1>
      <ImagesDisplay
        carouselHeight={`${deviceType === "Mobile" ? "250px" : "300px"}`}
        imageHeight={`${deviceType === "Mobile" ? "250px" : "300px"}`}
        imageWidth={`${deviceType === "Mobile" ? "500px" : "600px"}`}
        imageUrls={new Array(8).fill(null).map((_, index: number) => {
          return `/Website Photos/infra${index + 1}.jpg`;
        })}
      ></ImagesDisplay>
      <div className={classes.infraContent}>
        <p id="intro">
          Our manufacturing plant is strategically located in Rajasthan, which
          is one of the major ISABGOL (PSYLLIUM HUSK) crop producing states in
          India. Spread across an expansive area of 60,000 square feet, our
          manufacturing facility is equipped with the most innovative technology
          and adheres to international standards.
        </p>
        <h3 className={classes.headings}>Top Quality Infrastructure</h3>
        <p>
          All our machines are bought from international manufacturers with good
          reputation. We maintain our superiority in the industry by keeping the
          machines with the latest cutting edge technology designed for optimal
          efficiency and product quality. All our machinery is made of
          food-grade stainless steel, ensuring the highest standards of hygiene
          and safety. Our manufacturing machinery is in compliance with the
          regulations set by the United States Food and Drug Administration
          (FDA).
        </p>
        <h3 className={classes.headings}>Cleaning</h3>
        <p>
          Our cleaning plant is equipped with de-stoners, air separators and
          gravity separators to remove foreign bodies. We employ pulse jet
          technology for husk cleaning, a cutting-edge process that efficiently
          removes husk impurities while preserving the natural integrity of the
          ISABGOL. This technology ensures that our ISABGOL products are free
          from any contaminants, delivering a premium and wholesome experience
          to our customers
        </p>
        <h3 className={classes.headings}>Sortex</h3>
        <p>
          Our sortex helps to make the safest and best quality food. With the
          combination of advanced cameras and shape recognition the sortex
          identifies foreign material, product damage and bad color seed.To
          maintain the purity and uniformity of our ISABGOL products, we utilize
          a state-of-the-art Sortex machine from Satake, Japan.
        </p>
        <h3 className={classes.headings}>Manufacturing Capacity</h3>
        <p>
          With our advanced manufacturing infrastructure, we have a production
          capacity of 20 tons per day. This significant capacity allows us to
          meet the increasing demand for our ISABGOL products while ensuring a
          consistent supply to our valued customers.
        </p>
        <h3 className={classes.headings}>Quality Control</h3>
        <p>
          We have a robust quality control system in place to maintain the
          highest standards of quality throughout the manufacturing process. Our
          quality control team conducts rigorous checks and tests at every stage
          to ensure that our ISABGOL products meet or exceed customer
          expectations.
        </p>
      </div>
    </div>
  );
}
