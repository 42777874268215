import { useEffect, useState } from "react";
export default function useImageLoader(imageName:string){
    const [image, setImage] = useState();
useEffect(() => {
  const loadImage =  async (imageName: string) =>{
    await import(`../assets/images/${imageName}`).then((image)=>{
      setImage(image.default)
    })
  }
  loadImage(imageName)
}, [imageName]);

    return image
}