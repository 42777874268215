import React from 'react';
import logo from './logo.svg';
import './App.css';
import { Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import NavBar from './components/NavBar';
import Footer from './components/Footer';
import Header from './components/Header';
import Accrediations from './pages/Accrediations';
import ContactUs from './pages/ContactUs';
import Products from './pages/Products';
import FixedImage from './components/FixedImage';
import Product from './pages/Product';
import Infrastructure from './pages/Infrastructure';
import AboutUs from './pages/AboutUs';
import Certifications from './pages/Certifications';
import NotFound from './pages/NotFound';
import ScrollToTop from './assets/utils/ScrollToTop';

function App() {
  return (
    <div className="siteWrapper">
    <Header></Header>
    <div className="wrapper">
    <ScrollToTop></ScrollToTop>
    <Routes>
      <Route path='/' element={<Home/>}></Route>
      <Route path='/accrediations' element={<Accrediations/>}></Route>
      <Route path='/products' element={<Products/>}></Route>
      <Route path='/products/:productName' element={<Product/>}></Route>
      <Route path='/contactus' element={<ContactUs/>}></Route>
      <Route path='/Infrastructure' element={<Infrastructure/>}></Route>
      <Route path='/AboutUs' element={<AboutUs/>}></Route>
      <Route path='/Certifications' element={<Certifications/>}></Route>
      <Route path='*' element={<NotFound/>}></Route>

    </Routes>
    </div>
    <Footer></Footer>
    <FixedImage imageName='man.png' bottom='-10px'/>
    </div>


  );
}

export default App;
