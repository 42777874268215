import classes from './Logo.module.css'
import logo from "../../assets/images/logo.png";
import logoMobile from "../../assets/images/logoMobile.png"
import useScreenResize from '../../hooks/useScreenResize';
 import { Link } from 'react-router-dom';
export default function Logo(){
    const deviceType = useScreenResize(960)
    return <div className={classes.logoContainer}>
        <Link to="/">
        <img className={classes.logo} src={logo} alt="GK Agro" title='GK Agro'/>
        </Link>
         
    </div>
}