import classes from "./ImageFrame.module.css"
import useScreenResize from "../../hooks/useScreenResize";
export interface ImageFrameProps {
    imageName: string; 
    width: string;
}
export default function ImageFrame({imageName, width}:ImageFrameProps){

    return <div className={classes.imageFrameWrapper} style={{width}}>
        <div className={classes.frameMargin}></div>
        <div className={classes.frameImageWrapper}>
            <img className={classes.frameImage} src={`/certificates/${imageName}`} alt="" />
        </div>
    </div>
}