import classes from "./Footer.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import email from "../../assets/images/icons8-email-48.png";
import address from "../../assets/images/icon8-address-40.png";
import instagram from "../../assets/images/icon8-instagram-48.png";
import linkedin from "../../assets/images/icons8-linkedin-48.png";
import call from "../../assets/images/icons8-call-50.png";

export default function Footer() {
  return (
    <div className={classes.wrapper}>
      <hr />
      <footer className={classes.footerContainer}>
        <div className={classes.footerContent}>
          <div className={classes.information}>
            <div className={classes.address}>
              <b>Manufacturing Unit & Head Office :</b>
              <p>
                Khasra No. 1213/1095 Bikasar, Near Balaji Agro, RIICO Industrial
                Area, PO Nokha, Dist. Bikaner, Pincode : 334803, Rajasthan
              </p>
            </div>
            <div className={classes.contactInfo}>
              <div className={classes.info}>
                <img className={classes.icons} src={call} alt="Contact" />
                <a href="tel:+918000520323">+918000520323</a>
              </div>

              <div className={classes.info}>
                <img className={classes.icons} src={linkedin} alt="linkedin" />
                <a href="https://www.linkedin.com/company/gkagroindustries/">
                  /gkagroindustries
                </a>
              </div>
              <div className={classes.info} style={{ alignItems: "center" }}>
                <div className={classes.emails}>
                  <div className={classes.email}>
                    <img className={classes.icons} src={email} alt="Email" />
                    <a href="mailto:customercare@gkagroindustries.in">
                      customercare@gkagroindustries.in
                    </a>
                  </div>
                  <div className={classes.email}>
                    <img className={classes.icons} src={email} alt="Email" />
                    <a href="mailto:info@gkagroindustries.in">info@gkagroindustries.in</a>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}
