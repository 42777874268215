import classes from "./Certifications.module.css";
import ImageFrame from "../../components/ImageFrame";
import useScreenResize from "../../hooks/useScreenResize";
export default function Certifications() {
  const deviceType = useScreenResize(960);

  return (
      <div className={classes.certificationsWrapper}>
        <h1>Certifications</h1>
        <div className={classes.certificateGroup}>

        
    { new Array(11).fill(0).map((_,index)=>{
        return <ImageFrame width={`${deviceType === "Desktop"?"45%":"90%"}`} imageName={`/Cert${index+1}.jpg`}></ImageFrame>
    })}

        </div>
      </div>
  );
}
