import classes from './MenuList.module.css'
import Button from '../Button';
import { NavLink } from 'react-router-dom';
export interface MenuListProps {
    label: string;
    optionsList: { label: string , link: string}[];
    size?: "small" | "medium" | "large";
    flowDirection?: "left" | "right" | "down";
    width?: string; 
    isActivated?: boolean;
    onClick?():void;
}

export default function MenuList({label,size="small",optionsList,flowDirection="down",width="150px",onClick, isActivated = false}:MenuListProps) {
    const listItems = <ul className={classes[flowDirection]} style={{width:`${flowDirection === "down"?"100%":"150px"}`}}>
        {optionsList.map((item,index)=>{
        return <NavLink to={item.link}>
            {({ isActive }) => (
          <Button key={index} label={item.label} hasIcon={false} type="simple" isActivated={isActive}  onClick={onClick}></Button>
        )}
        </NavLink>
    }) }
    </ul>
  return <div className={`${classes.menuListContainer} ${classes[size]}`}>
    <div className={classes.heading}>
            <Button label={label} isActivated={isActivated} hasIcon={false} width={width} type="navButton"  iconName="arrow"></Button>
    </div>
    {listItems}
  </div>;
}
