import React, { useEffect, useState } from "react";

type DeviceType = "Desktop" | "Mobile";

export default function useScreenResize(mobileMaxWidth = 768) {

  const [deviceType, setDeviceType] = useState<DeviceType>(
    window.innerWidth > mobileMaxWidth?"Desktop":"Mobile");

  useEffect(()=>{
    const resizeCallback = (event:UIEvent) => {
      const windowTarget = event.target as Window;
      const isDesktop = windowTarget.innerWidth > mobileMaxWidth;
      setDeviceType(isDesktop ? "Desktop" : "Mobile");
    }
    window.addEventListener('resize',resizeCallback);

    return () => {
      window.removeEventListener('resize',resizeCallback);
    }
  },[mobileMaxWidth]);

  return deviceType;
}
