import classes from "./ReadMoreSection.module.css"
import { Link } from "react-router-dom"
import { ReactNode } from "react";
export interface ReadMoreSectionProps {
    heading: string;
    image1Name: string;
    image2Name: string;
    image3Name: string;
    children? : ReactNode
    link: string;
}
export default function ReadMoreSection ({ heading, image1Name, image2Name, image3Name, children, link }:ReadMoreSectionProps){
    return       <div className={classes.readMoreSection}>
    <div className={classes.readMoreHeading}>
      <div>{heading}</div>
      <div className={classes.line}></div>
    </div>
    <div className={classes.readMoreContent}>
      <div className={classes.imgContainer}>
        <img src={`/Website Photos/${image1Name}`} alt="" />
        <div className={classes.vertImages}>
          <img src={`/Website Photos/${image2Name}`} alt="" />
          <img src={`/Website Photos/${image3Name}`} alt="" />
        </div>
      </div>

      <div className={classes.readMoreText}>
        <div>
          {children}
          <span><Link to={link} className={classes.readmoreLink}>Click to Read more</Link></span>
        </div>
      </div>
    </div>
  </div>
}