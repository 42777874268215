import classes from './ImageTile.module.css'
export interface ImageTileProp  {
    imageScr: string,
    width?: string,
    height?: string,
}
export default function ImageTile({imageScr, height="100px", width="100px"}: ImageTileProp) {
    return <div className={classes.ImageTileWrapper}>
        <img draggable={false} src={imageScr} className={classes.image} style={{width,height}} alt="" />
    </div>
}