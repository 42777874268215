import classes from "./Button.module.css";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export interface ButtonProps {
  label: string;
  type: "navButton" | "simple" | "dropDown";
  size?: "small" | "medium" | "large";
  width?: string;
  hasIcon?: boolean;
  borderRadius?: string;
  iconName?: "arrow";
  isActivated?: boolean;
  onClick?(): void;
}
export default function Button({
  label,
  size = "small",
  type = "simple",
  width = "100%",
  isActivated = false,
  borderRadius = "0px",
  hasIcon = false,
  iconName = "arrow",
  onClick,
}: ButtonProps) {

  return (
    <div
      style={{ width }}
      className={`${classes.buttonContainer} ${isActivated?classes.isActivated:""} ${classes[size]}`}
      onClick={onClick}
    >
      {type === "simple" && <button className={classes.button}>{label}</button>}
      {type === "navButton" && (
        <button className={`${classes.button}`} style={{fontSize:"18px"}}>{label}</button>
      )}
      {type === "dropDown" && (
        <button className={classes.button}>{label}</button>
      )}
      {
        hasIcon && <FontAwesomeIcon className={`${classes.icon}`} icon={faChevronDown}></FontAwesomeIcon>
      }
    </div>
  );
}
